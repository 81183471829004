@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "antd/dist/antd.css";
// font-family: 'Roboto', sans-serif;
// font-family: 'Roboto Condensed', sans-serif;

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  letter-spacing: 0.5px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  min-width: 160px;
  min-height: 18px;
  background-color: #999;
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

::-webkit-scrollbar-thumb:active {
  background-color: #444;
}

a {
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.ant-layout {
  background: none;
}
.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0px 15px;
}
.ant-btn {
  background: #fd6400;
  min-height: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #ffffff;
    background: #fd6400;
  }
  &.ant-btn-primary {
    background: #fd6400;
  }
  &.ant-btn-secondary {
    background: #f3f3f3;
    color: #343434;
    &:hover,
    &:focus {
      color: #343434;
      background: #f3f3f3;
    }
  }
  &.ant-btn-sm {
    font-size: 16px;
    min-height: 35px;
    display: inline-flex;
    align-items: center;
  }
  .anticon {
    font-size: 18px;
    display: inline-flex;
  }
}
.front-header {
  width: 100%;
  position: relative;
  background: none;
  height: auto;
  padding: 0;
  height: 82px;
  position: fixed;
  z-index: 999;
  background: #fff;
  line-height: normal;
  top: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  .ant-dropdown {
    .ant-anchor-link {
      padding: 10px 16px;
      border-bottom: 1px solid #f1f1f1;
    }
    .ant-anchor-link-active > .ant-anchor-link-title {
      color: #000;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1230px;
    padding: 0px 15px;
    margin: 0 auto;
  }
  .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    margin: 10px 0px;
    display: inline-flex;
    font-size: 52px;
    font-weight: bold;
    color: #fd6400;
    align-items: center;
    img {
      max-width: 130px;
      height: auto;
    }
  }
  .rightdiv {
    display: flex;
    align-items: center;
    .notify {
      position: relative;
      .anticon {
        font-size: 24px;
        cursor: pointer;
      }
      .ant-dropdown-menu-items {
        min-width: 400px;
        margin: 0;
        padding: 0;
        .ant-list-item-action a {
          color: #fd6400;
        }
      }
      .count {
        display: inline-flex;
        width: 20px;
        height: 20px;
        font-size: 11px;
        align-items: center;
        justify-content: center;
        background: #fd6400;
        color: #fff;
        border-radius: 20px;
        position: absolute;
        right: -10px;
        top: -10px;
      }
      // .ant-btn{

      // }
    }
    .walletlink {
      border-radius: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      color: #343434;
      font-size: 14px;
      padding: 10px 16px;
      border: 1px solid #d6d1d1;
      position: relative;
      padding-left: 35px;
      white-space: nowrap;
      &:before {
        position: absolute;
        left: 15px;
        top: 13px;
        width: 10px;
        height: 10px;
        content: "";
        background: #4caf50;
        border-radius: 50%;
      }
    }
    .logoutlink {
      border-radius: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      color: #343434;
      font-size: 14px;
      padding: 10px 16px;
      border: 1px solid #d6d1d1;
      position: relative;
    }
    .ant-menu {
      display: flex;
      margin: 0;
      background: none;
      line-height: normal;
      .ant-menu-item {
        display: inline-block;
        border-bottom: 3px solid #fff;
        padding: 0 !important;
        margin: 0;
        cursor: pointer;
        font-size: 16px;
        width: auto;
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          color: #343434;
          padding: 3px 15px;
          display: inline-block;
        }
      }
      &.ant-menu-horizontal {
        border: 0;
      }
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
      border-bottom: 3px solid #fd6400;
    }
    .ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-horizontal > .ant-menu-submenu::after {
      display: none;
    }
    .ant-btn {
      display: inline-block;
      min-height: 40px;
      &.ant-btn-primary {
        border: 1px solid #9a9a9a;
        background: #fff;
        color: #343434;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        border-radius: 0;
        margin-left: 15px;
        align-items: center;
        padding: 0.375rem 0.75rem;
        display: inline-flex;
        img {
          margin-right: 5px;
        }
        &:hover {
          border: 1px solid #fd6400;
          background: #fd6400;
        }
      }
    }

    .mmenu {
      display: none;
    }
    .demnu {
      display: block;
    }
  }
  .rightdiv .ant-dropdown {
    .ant-list-item-meta-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      line-height: 1.5715;
      margin-bottom: 0;
    }
    .ant-list-item-meta-description {
      font-size: 13px;
    }
    .ant-list-item {
      border-bottom: 1px solid #f1f1f1;
      align-items: flex-start;
      &:last-child {
        border: 0;
      }
    }
    .ant-btn {
      font-size: 13px;
      min-height: 30px;
      padding: 5px 10px;
      background: none;
      border: 1px solid #fd6400;
      color: #fd6400;
      border-radius: 30px;
      .anticon {
        font-size: 16px;
      }
    }
  }
}
.homepage {
  background: url(../../images/homebg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 82px;
  min-height: calc(100vh - 36px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .bannerimg {
    padding: 0px 32px;
    .imgwrp {
      background: #fff;
      border-radius: 50%;
    }
    img {
      max-width: 500px;
    }
  }
  .bannercontent {
    padding-right: 64px;
    .ant-btn {
      padding: 0px 20px;
      font-size: 20px;
    }
    h3 {
      font-size: 56px;
      margin: 0;
      margin-bottom: 16px;
      font-weight: 800;
      line-height: normal;
    }
    h5 {
      font-size: 32px;
      font-weight: normal;
      margin: 0;
      margin-bottom: 16px;
      color: #343434;
      line-height: normal;
    }
  }
}
.ant-layout-footer {
  background: #fff;
  padding: 16px;
  display: flex;
  height: 36px;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  box-sizing: border-box;
}
.frontContent {
  padding-top: 82px;
  box-sizing: border-box;
  min-height: calc(100vh - 36px);
  .pagename {
    font-size: 26px;
    font-weight: 600;
    color: #343434;
    padding: 16px 0px;
  }
  .contentbody {
    padding: 32px;
    font-size: 16px;
    line-height: 22px;
    color: #343434;
    background: #fff;
    border: 1px solid #f1f1f1;
  }
}
div#root {
  height: 100%;
}
.AdminApp {
  height: 100%;
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .ant-layout {
    background: none;
    // height: 100%;
    position: relative;
    z-index: 9;
    &.front {
      height: auto;
      .ant-layout-header {
        .hlogo {
          min-width: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          min-height: 90px;
        }
      }
    }
  }
  .ant-layout-content {
    min-height: 100%;
  }
  .ant-layout-footer {
    background: #343434;
    padding: 50px 0px;
    color: #fff;
    display: none;
    .logo {
      margin-bottom: 30px;
      display: inline-block;
    }
    .socialicons {
      li {
        display: inline-block;
        margin-right: 10px;
      }
      a {
        font-size: 32px;
        color: #343434;
        &:hover {
          color: #fff;
        }
      }
    }
    h3 {
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.01em;
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 16px;
      &::before {
        content: "";
        width: 100%;
        max-width: 130px;
        height: 1px;
        border-top: 1px solid #fff;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.01em;
      margin: 0;
    }
    .link {
      color: #343434;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.01em;
    }
  }
  .ant-dropdown-menu {
    border-radius: 5px;
    padding: 16px;
    margin-top: 10px;
    position: relative;
    &::before {
      position: absolute;
      right: 10px;
      top: -7px;
      display: inline-block;
      width: 0;
      height: 0;
      vertical-align: middle;
      border-bottom: 10px solid #fff;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      content: "";
    }
    .ant-dropdown-menu-item {
      border-bottom: 1px solid #f1f1f1;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #343434;
        display: flex;
        align-items: center;
        .menu-icon {
          font-size: 18px;
          margin-right: 7px;
          width: 20px;
          color: #343434;
        }
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .ant-layout-header {
    background: #fff;
    border-image: none;
    height: 90px;
    padding: 0px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    display: flex;
    align-items: center;

    .hlogo {
      min-width: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 230px;
      min-height: 90px;
    }
    .logo {
      min-width: inherit;
      display: flex;
      align-items: center;
      background: none;
      min-height: 90px;
      a {
        display: flex;
      }
      svg {
        max-width: 175px;
        height: auto;
      }
    }
    .rightside {
      display: inline-flex;
      align-items: center;
    }
    .headerbtn {
      height: 44px;
      background: #fff;
      width: 44px;
      overflow: hidden;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #343434;
      font-size: 20px;
      min-height: inherit;
    }
    .profilebtn {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      .imgd {
        border-radius: 50%;
        overflow: hidden;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }

      .imgd.ant-dropdown-trigger.profile-placeholder {
        background: #fff;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .imgd.ant-dropdown-trigger.profile-placeholder img {
        width: 45px !important;
        border-radius: 50%;
      }
    }
    .topmenu {
      display: none;
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        text-transform: uppercase;
        padding: 10px 16px;
        border-bottom: 2px solid #343434;
        margin: 0px 5px;
        &.active,
        &:hover {
          color: #000;
          border-bottom: 2px solid #000;
        }
      }
    }

    .ant-btn-primary {
      background: #fff;
      color: #000;
      min-height: 44px;
      border-radius: 0;
      line-height: normal;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .anticon {
        font-size: 22px;
        display: inline-flex;
      }
    }

    .searchhwrap {
      min-height: 44px;
      max-height: 44px;
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      .ant-input-affix-wrapper {
        border: 0;
        min-height: 44px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        box-shadow: none;
        min-width: 320px;
        vertical-align: top;
        border-radius: 5px;
        .ant-input-suffix {
          font-size: 20px;
        }
      }
    }
  }
  .ant-layout-sider {
    background: #343434;
    z-index: 99;
    height: 100vh;
    -webkit-transition: none;
    transition: none;
    .userName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      padding: 16px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .logo {
      min-width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      min-height: 90px;
      a {
        display: flex;
      }
      img {
        max-width: 130px;
        height: auto;
      }
    }

    .ant-layout-sider-children {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        background: hsla(0, 0%, 100%, 0.2);
        border-radius: 3px;
      }
      &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.15);
        border-radius: 3px;
      }
    }
    .ant-layout-sider-zero-width-trigger {
      background: #343434;
      top: 145px;
    }
    .ant-layout-sider-zero-width-trigger:hover::after {
      background: none;
    }
    .site-layout-background {
      position: absolute;
      top: 10px;
      right: -36px;
      z-index: 1;
      width: 36px;
      height: 42px;
      color: #fff;
      font-size: 18px;
      line-height: 42px;
      text-align: center;
      background: #001529;
      border-radius: 0 2px 2px 0;
      cursor: pointer;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
    }
    &.ant-layout-sider-zero-width {
      .ant-layout-sider-children {
        padding: 0;
        overflow: hidden;
      }
    }
    .sidebar_menu {
      padding: 16px;
    }
    .profilebar {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      color: #fff;
      padding: 16px;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      .imgd {
        border-radius: 50%;
        overflow: hidden;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
    }

    .ant-menu-inline {
      border: 0;
      background: none;
      color: rgba(255, 255, 255, 0.5);
      transition: none;
      .ant-menu-sub {
        background-color: rgba(0, 0, 0, 0.1);
        .ant-menu-item,
        .ant-menu-submenu-title {
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
          padding: 10px 0px 10px 30px !important;
          font-size: 14px;
        }
      }
      .ant-menu-submenu-selected {
        color: #fff;
        .commonicon {
          opacity: 1;
        }
      }
    }

    .ant-menu-item a {
      color: rgba(255, 255, 255, 0.5);
      white-space: normal;
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
    .ant-menu-item,
    .ant-menu-submenu {
      .commonicon {
        width: 24px;
        font-size: 24px;
        opacity: 0.5;
        margin-right: 10px;
        height: auto;
        path {
          fill: #fff;
        }
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
      color: rgba(255, 255, 255, 1);
      .commonicon {
        opacity: 1;
      }
    }
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
      color: rgba(255, 255, 255, 1);
      .commonicon {
        opacity: 1;
      }
    }
    .ant-menu-inline .ant-menu-selected::after,
    .ant-menu-inline .ant-menu-item-selected::after {
      display: none;
    }
    .ant-menu-inline .ant-menu-submenu-title {
      padding: 0 !important;
    }
    .ant-menu-inline .ant-menu-item,
    .ant-menu-inline .ant-menu-submenu-title {
      width: calc(100% + 1px);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0;
      padding: 14px 0px !important;
      height: auto;
      font-size: 14px;
      display: flex;
      align-items: center;
      line-height: 22px;
    }
    .ant-menu-item .ant-menu-item-icon + span,
    .ant-menu-submenu-title .ant-menu-item-icon + span,
    .ant-menu-item .anticon + span,
    .ant-menu-submenu-title .anticon + span {
      transition: none;
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: rgba(255, 255, 255, 1);
      .commonicon {
        opacity: 1;
      }
    }
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: none;
    }
    .ant-menu-item .ant-menu-item-icon,
    .ant-menu-submenu-title .ant-menu-item-icon,
    .ant-menu-item .anticon,
    .ant-menu-submenu-title .anticon {
      font-size: 20px;
    }
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow {
      color: rgba(255, 255, 255, 1);
    }
  }
  .container {
    padding: 0px 15px;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
  }
  .container-fluid {
    padding: 0px 16px;
    width: 100%;
    margin: 0 auto;
  }
  .contentpart {
    padding-top: 90px;
    min-height: 100%;
    background: #f5f5f5;
    height: 100%;
    .right_content_col {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      height: 100%;
      max-height: 100%;
      min-height: 100%;
      .pagename {
        padding: 10px 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        color: #343534;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 55px;
        .ant-breadcrumb {
          font-size: 16px;
        }
      }
      .content_wrapper {
        padding: 32px;
        padding-top: 0;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 1px;
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          background: hsla(0, 0%, 100%, 0.2);
          border-radius: 3px;
        }
        &::-webkit-scrollbar-track {
          background: hsla(0, 0%, 100%, 0.15);
          border-radius: 3px;
        }
      }
    }
  }
  .dashboardPage {
    .card_wrap {
      margin-bottom: 32px;
    }
    .ant-spin-nested-loading {
      position: relative;
      z-index: 9;
      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: #343434;
      }
    }
    .ant-spin-dot-item {
      background-color: #343434;
    }

    .chart-wrapper {
      padding: 16px;
      background: #fff;
      border: 1px solid #ebebeb;
      width: 100%;
      position: relative;
      &.chart_mainwrap {
        margin-bottom: 32px;
      }
      .headingrow {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #333;
      }
      .mainchart_title {
        text-align: center;
        padding: 16px;
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #333;
          position: relative;
          padding-bottom: 16px;
          margin-bottom: 16px;
          &::before {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            max-width: 330px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background: #f1f1f1;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            display: inline-block;
            margin: 0px 10px;
            padding-left: 20px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #7e7e7e;
            position: relative;
            &::before {
              content: "";
              width: 10px;
              height: 10px;
              left: 0;
              top: 4px;
              background: #2d99ff;
              display: inline-block;
              position: absolute;
              border-radius: 2px;
            }
            &.green {
              &::before {
                background: #81ddc6;
              }
            }
            &.purple {
              &::before {
                background: #826af9;
              }
            }
            &.pink {
              &::before {
                background: #ff4d8d;
              }
            }
            &.orange {
              &::before {
                background: #ff6c40;
              }
            }
            &.yellow {
              &::before {
                background: #ffe700;
              }
            }
            &.red {
              &::before {
                background: #f45e58;
              }
            }
          }
        }
      }
    }
    .subcardwrap {
      border-left: 1px solid #f1f1f1;
      padding: 16px 16px 0px 32px;
      height: 100%;
      .subcard {
        background: #42495a;
        padding: 16px;
        border-radius: 4px;
        text-align: center;
        color: #fff;
        margin-bottom: 32px;
        &.orange {
          background: #ec574c;
          margin: 0;
        }
        .count {
          width: 80px;
          height: 80px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          font-style: normal;
          font-weight: 500;
          font-size: 37px;
          line-height: 20px;
        }
        h3 {
          margin: 0;
          margin-top: 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          color: #fff;
        }
      }
    }
    .doctablewrap {
      background: #fff;
      border: 1px solid #ebebeb;
      padding: 16px;
      .headingrow {
        padding: 10px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #333;
        h3 {
          margin: 0;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.01em;
          .count {
            width: 25px;
            height: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #c4c4c4;
            color: #333;
            margin-left: 5px;
            border-radius: 50%;
            font-size: 16px;
          }
        }
      }
      .ant-table-tbody > tr:last-child > td {
        border: 0;
      }
    }
    .empmonthwrap {
      background: #fff;
      border: 1px solid #ebebeb;
      padding: 16px;
      .headingrow {
        padding: 10px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #333;
        h3 {
          margin: 0;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.01em;
          .count {
            width: 25px;
            height: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #c4c4c4;
            color: #333;
            margin-left: 5px;
            border-radius: 50%;
            font-size: 16px;
          }
        }
      }
      .ant-table-tbody > tr:last-child > td {
        border: 0;
      }
    }
    .ant-card {
      border: 1px solid #ebebeb;
      min-height: 100%;
      .ant-row {
        flex-flow: row nowrap;
        padding-right: 16px;
        align-items: center;
      }
      .ant-card-body {
        min-height: 100%;

        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 29px;
          line-height: 24px;
          color: #3e3e3e;
          margin: 0;
          margin-top: 4px;
          background: #e9e9e9;
          padding: 10px;
          display: inline-flex;
          min-width: 50px;
          min-height: 50px;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
        }
        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #7e7e7e;
          margin: 0;
          margin-top: 7px;
        }
        .cardicon {
          margin: 0px 15px 0px 0px;
        }
      }
    }

    .settingWrp {
      position: relative;
    }
    .ant-picker {
      border: 1px solid #e1e1e1;
      border-radius: 30px;
      min-height: 45px;
      &.ant-picker-range .ant-picker-active-bar {
        background: #b8b8b8;
      }
    }
    .productList {
      border: 1px solid #ebebeb;
      background: #fff;
      &.mystatsgrid {
        margin-bottom: 32px;
        .statshead {
          display: inline-flex;
          .searchhproduct {
            margin-right: 8px;
          }
          .monthwrap {
            margin-right: 8px;
          }
        }
      }
      .noProduct {
        padding: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000;
        .fav-button {
          margin-right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 46px;
          background: #f5f5f5;
          padding: 5px 15px;
          border-radius: 25px;
          cursor: pointer;
          &.checked {
            background: #233f8f;
            color: #fff;
          }
          label {
            margin-right: 10px;
            font-size: 16px;
            opacity: 0.7;
            cursor: pointer;
          }
        }
        h3 {
          margin: 0;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 0.01em;
        }
        .monthwrap {
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            height: 36px;
            padding: 7px 15px;
          }
        }
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 46px;
    padding: 12px 15px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 20px;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #e0e0e0;
    border-right-width: 1px !important;
  }
  .ant-input,
  .ant-input-number-input {
    border: 1px solid #dddddd;
    min-height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 12px 15px;
    &:-internal-autofill-selected {
      background-color: transparent !important;
    }
    &:-webkit-autofill {
      background-color: transparent !important;
    }
  }
  .ant-select.ant-select-multiple:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid #dddddd;
    min-height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 8px 15px 8px 10px;
  }
  .ant-picker {
    border: 1px solid #dddddd;
    min-height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 8px 30px 8px 10px;
    width: 100%;
  }
  .ant-input-number {
    width: 100%;
    border: 0 none;
    border-radius: 0;
  }
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    color: #ff4d4f;
    text-align: right;
  }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-number-input,
  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-number-focused,
  .ant-form-item-has-error .ant-picker-focused,
  .ant-form-item-has-error .ant-input-number:focus,
  .ant-form-item-has-error .ant-input-number-input:focus,
  .ant-form-item-has-error .ant-picker:focus {
    border-color: #ff4d4f;
    box-shadow: none;
  }
  .ant-input:focus,
  .ant-input-number-input:focus,
  .ant-input-focused {
    border-color: transparent;
    border: 1px solid #dddddd;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-input:hover,
  .ant-input-number-input:hover {
    border-color: transparent;
    border: 1px solid #dddddd;
  }
  .ant-form-item textarea.ant-input {
    resize: none;
  }
  .ant-input-affix-wrapper {
    border: 1px solid #dddddd;
    min-height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding: 12px 15px;
    &:hover {
      border-color: #dddddd;
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
    .ant-input,
    ant-input-number-input {
      min-height: 20px;
      border: 0;
    }
  }
  .ant-form-item {
    margin-bottom: 32px;
  }
  .ant-form label {
    font-weight: 500;
  }
  .ant-divider-horizontal {
    margin: 16px 0px 32px 0px;
    border-top: 1px solid #e7e7e7;
  }
  .ant-checkbox-inner {
    border: 1px solid #2b7171;
    border-radius: 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2b7171;
    border-color: #2b7171;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #2b7171;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #2b7171;
  }
  .gridwrP {
    background: #fff;
    border: 1px solid #eeeeee;
    padding: 0;
    .heading {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #000000;
      padding: 16px;
      .ant-row {
        width: 100%;
      }
    }

    .searchhwrap {
      min-height: 36px;
      max-height: 36px;
      display: inline-block;
      vertical-align: top;
      margin-left: 10px;
      &.big {
        min-width: 300px;
      }
      .ant-input-affix-wrapper {
        padding: 0;
        border: 0;
        min-height: 34px;
      }
      .ant-input-group {
        overflow: hidden;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        border-radius: 5px;
        .ant-input {
          border: 0;
          min-height: 34px;
          min-width: 40px;
          padding: 3px 10px;
        }
        .ant-input-group-addon {
          background-color: transparent;
          .ant-btn {
            background: none;
            padding: 0;
            min-height: 34px;
          }
        }
      }
    }
    .cardwrp {
      padding: 0px 32px;
    }
    .productCard {
      margin: 0;
      background: #ffffff;
      border: 1px solid #e7e7e7;
      border-radius: 10px;
      margin-bottom: 32px;
      cursor: pointer;
      .add_to_cart {
        padding: 10px;
        background: #f5f5f5;
        display: flex;
        justify-content: center;
        .ant-checkbox-wrapper {
          font-size: 16px;
          font-weight: 500;
          color: #343434;
        }
        .ant-btn.ant-btn-primary {
          padding: 5px 10px;
          min-height: inherit;
        }
      }
      .buybtnwrap {
        padding: 10px;
        background: none;
        display: flex;
        justify-content: center;
        padding-top: 0;
        .ant-btn.ant-btn-primary {
          padding: 5px 10px;
          min-height: inherit;
          width: 100%;
          font-size: 16px;
        }
      }
      .imgDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 350px;
        max-height: 350px;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid #f1f1f1;
        .stock {
          position: absolute;
          background-color: #28a745;
          padding: 2px 5px;
          color: #fff;
          right: 0;
          bottom: 0;
        }
        .outofstock {
          position: absolute;
          background-color: #fd6400;
          padding: 2px 8px;
          color: #fff;
          right: 0px;
          top: 0px;
          font-size: 13px;
          border-radius: 0px 10px;
          border: 1px solid #343434;
          &.RESERVED {
            color: #1d39c4;
            background: #f0f5ff;
            border-color: #adc6ff;
          }
          &.RETIRED {
            color: #d4b106;
            background: #feffe6;
            border-color: #fffb8f;
          }
          &.ISSUED {
            color: #343434;
            background: #fff2e8;
            border-color: #ffbb96;
          }
          &.SETTLED {
            color: #389e0d;
            background: #f6ffed;
            border-color: #b7eb8f;
          }
        }
        img {
          max-width: 100%;
        }
      }
      .descp {
        padding: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #343434;
        .qtyrow {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          background: #f5f5f5;
          padding: 10px;
          margin-top: 10px;
          border-radius: 3px;
          strong {
            font-size: 14px;
            display: block;
          }
        }
        p {
          margin-bottom: 0;
          min-height: 42px;
        }
        .morecon {
          margin-bottom: 10px;
          padding-top: 10px;
          padding-bottom: 5px;
          border-top: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;
          .item {
            display: flex;
          }
          .vlu {
            margin-right: 16px;
            font-size: 14px;
            font-weight: 500;
          }
          label {
            font-weight: 600;
            margin-right: 10px;
          }
          p {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 5px;
          }
        }
        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin: 0;
          margin-bottom: 5px;
          span {
            display: block;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
            opacity: 0.5;
          }
        }
        .btnR {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          .ant-btn {
            margin: 5px 0px 5px 10px;
            &.bin-btn-active {
              background-color: #28a745;
            }
            &.bin-btn-inactive {
              background-color: rgb(218, 13, 13);
            }
          }
        }
      }
    }
  }
  .marketplaceDetail {
    padding: 32px;
    .prodimg {
      width: 100%;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e2e2e2;
      position: relative;
      border-radius: 10px;
      img {
        max-width: 500px;
      }
      .outofstock {
        position: absolute;
        background-color: #fd6400;
        padding: 2px 8px;
        color: #fff;
        right: 0px;
        top: 0px;
        font-size: 16px;
        border-radius: 0px 10px;
        border: 1px solid #343434;
        &.RESERVED {
          color: #1d39c4;
          background: #f0f5ff;
          border-color: #adc6ff;
        }
        &.RETIRED {
          color: #d4b106;
          background: #feffe6;
          border-color: #fffb8f;
        }
        &.ISSUED {
          color: #343434;
          background: #fff2e8;
          border-color: #ffbb96;
        }
        &.SETTLED {
          color: #389e0d;
          background: #f6ffed;
          border-color: #b7eb8f;
        }
      }
    }
    .rightcl {
      h3 {
        font-size: 22px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e2e2;
        .ant-space {
          justify-content: space-between;
          width: 100%;
          .ant-btn.ant-btn-primary {
            padding: 5px 16px;
            min-height: inherit;
            line-height: normal;
          }
        }
      }
      h5 {
        font-weight: 600;
        font-size: 18px;
        margin: 0;
        margin-top: 16px;
        position: relative;
        padding-bottom: 5px;
        margin-bottom: 5px;
        &::before {
          position: absolute;
          height: 2px;
          width: 120px;
          content: "";
          background: #fd6400;
          bottom: 0;
          left: 0;
        }
      }
      .descp {
        font-weight: normal;
        color: #555555;
        font-size: 16px;
      }
      .propertyCont {
        padding-top: 10px;
        .propcard {
          padding: 10px;
          background: #f5f5f5;
          border: 1px solid #f1f1f1;
          text-align: center;
          font-size: 13px;
          font-weight: normal;
          color: #fd6400;
          margin-bottom: 16px;
          line-height: 20px;
          h4 {
            font-size: 14px;
            font-weight: 600;
            color: #343434;
            margin: 0;
            margin-top: 3px;
          }
        }
      }
    }
    .ant-collapse {
      border: 1px solid #f5f5f5;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: 1px solid #f5f5f5;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: #343434;
      font-weight: bold;
    }
    .ant-table-tbody > tr > td {
      word-break: break-word;
    }
    .ant-table-thead > tr > th {
      position: relative;
      padding: 10px;
      overflow-wrap: break-word;
      line-height: 20px;
    }
  }
  .bulwrp {
    .ant-upload-text-icon,
    .ant-upload-list-item-card-actions,
    .ant-upload-list-item-progress {
      display: none;
    }
    .ant-upload-list-item-name {
      padding: 0;
    }
  }
  .lbl {
    margin-top: 8px;
    display: block;
  }
  .csvlink {
    margin-top: 8px;
    display: block;
  }
  .table_grid {
    .action {
      color: #343434;
      font-size: 22px;
      background: #fff2e8;
      border: 1px solid #ffbb96;
    }
    .commonicon.inactive {
      width: 45px;
      font-size: 26px;
      opacity: 0.7;
      margin-right: 10px;
      height: auto;
    }
    .commonicon.gallery {
      width: 22px;
      font-size: 26px;
      opacity: 0.7;
      margin-right: 10px;
      height: auto;
    }
    .anticon {
      color: #343434;
      font-size: 18px;
    }
    .ant-space-item {
      margin-right: 10px !important;
    }
    .ant-table-thead > tr > th {
      background: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8e8e8e;
      text-transform: capitalize;
      padding: 16px 8px;
      &:first-child {
        padding-left: 16px;
      }
    }
    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #343534;
      padding: 8px 8px;
      &:first-child {
        padding-left: 16px;
      }
      &.ant-table-column-sort {
        background: none;
      }
    }
    .ant-table-column-sorters {
      padding: 0;
      .ant-table-column-sorter {
        .ant-table-column-sorter-down.active,
        .ant-table-column-sorter-up.active {
          color: #0c5960;
        }
        color: #bfbfbf;
        .anticon {
          font-size: 11px;
          color: #bfbfbf;
        }
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #fafafa;
      &.ant-table-column-sort {
        background: #fafafa;
      }
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: #fafafa;
      border-color: rgba(0, 0, 0, 0.03);
    }
  }
  .no-data {
    text-align: center;
    padding: 32px;
    img {
      opacity: 0.5;
      max-width: 150px;
    }
    h3 {
      margin-top: 32px;
      opacity: 0.8;
    }
  }
  .ant-pagination {
    padding: 0px 16px;
  }
  .datewrap {
    padding: 5px 0px;
    .ant-btn.ant-btn-primary {
      padding: 5px 10px;
      min-height: 34px;
    }
    label {
      font-size: 14px;
      color: #343434;
    }
    .ant-picker {
      border-radius: 5px;
      min-height: 34px;
      padding: 5px 10px 5px 10px;
    }
    .ant-picker-focused {
      box-shadow: none;
    }
  }
  .formPage {
    .formWrapper {
      padding: 32px;
      background: #fff;
      border: 1px solid #ebebeb;
      width: 100%;
      position: relative;
      margin-bottom: 32px;
      .availcredit {
        text-align: right;

        label {
          background: #2b7171;
          padding: 3px 7px;
          color: #fff;
          margin-bottom: 7px;
          display: inline-block;
          font-weight: 400;
          strong {
            font-size: 16px;
          }
        }
      }
    }
    .filewrap {
      margin-top: 10px;
      h4 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
      }
      .ant-space {
        align-items: center;
      }
      .ant-btn {
        background: none;
        color: #fd6400;
      }
    }
  }
}

.lgoin-wrap {
  text-align: center;
  min-height: calc(100vh - 136px);
  .inside {
    width: 100%;
    max-width: 430px;
    display: inline-block;
    padding: 32px 16px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    margin-top: 32px;

    .remeberwrp .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-checkbox-wrapper {
        color: #fff;
        display: flex;
      }
      a {
        color: #fff;
      }
    }
    .signupline {
      text-align: center;
      color: #8e8e8e;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      margin: 0;
      margin-top: 32px;
      a {
        color: #fff;
        margin-left: 10px;
      }
    }
    .btnwrp {
      text-align: center;
      margin: 0;
    }
  }
}

.activateaccount {
  h5 {
    font-size: 28px;
    letter-spacing: 1px;
    color: #343434;
    font-weight: normal;
    padding: 10px 5px;
    margin: 0;
    margin-top: 16px;
    font-weight: bold;
  }
  .activation-note {
    color: #343434;
    text-align: center;
    padding: 32px 16px;
    .msg {
      display: block;
      margin-top: 64px;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
}

$size: 20px;
$yellow_light: #fed750;
$yellow: #d86f00;
$yellow_dark: #fed750;
$duration: 2s;

@mixin psuedo {
  display: block;
  content: "";
}

.loader {
  display: inline-flex;
  flex-wrap: wrap;
  width: $size * 3;
  height: $size * 3;
  transform-style: preserve-3d;
  transform: rotateX(45deg) rotate(45deg);
}

.box {
  position: absolute;
  top: 0;
  left: 0;
  width: $size;
  height: $size;
  background: $yellow_light;
  box-shadow: $size * 2.75 $size * 2.75 20px #000;
  animation: move $duration ease-in-out infinite both;
  transform-style: preserve-3d;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: $i/2 * -$duration;
    }
  }

  &:before,
  &:after {
    @include psuedo;
    position: absolute;
    width: $size;
    height: $size;
  }
  &:before {
    top: 100%;
    left: 0;
    background: $yellow;
    transform-origin: center top;
    transform: rotateX(-90deg);
  }
  &:after {
    top: 0;
    left: 100%;
    background: $yellow_dark;
    transform-origin: center left;
    transform: rotateY(90deg);
  }
}

@keyframes move {
  0%,
  100% {
    transform: none;
  }
  12.5% {
    transform: translate($size, 0);
  }
  25% {
    transform: translate($size * 2, 0);
  }
  37.5% {
    transform: translate($size * 2, $size);
  }
  50% {
    transform: translate($size * 2, $size * 2);
  }
  62.5% {
    transform: translate($size, $size * 2);
  }
  75% {
    transform: translate(0, $size * 2);
  }
  87.5% {
    transform: translate(0, $size);
  }
}

.ant-btn.checkout-btn {
  position: fixed;
  bottom: 32px;
  right: 48px;
  z-index: 9;
  font-size: 18px;
  .anticon {
    font-size: 20px;
  }
}

.skeleton-img {
  width: 100% !important;
  height: 300px !important;
  margin-bottom: 30px;
}

.skeleton20 {
  width: 100% !important;
  height: 20px;
  margin-bottom: 35px;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 100%, 0.2);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, 0.15);
    border-radius: 3px;
  }
}

.infinite-scroll-component__outerdiv {
  height: 100%;
}

.h11 {
  height: 100%;
}
.placeOrderModel {
  .ant-form-item-label > label::before {
    position: absolute;
    right: -15px;
  }
  .ant-btn.ant-btn-primary {
    padding: 5px 16px;
    min-height: inherit;
    line-height: normal;
    height: 34px;
  }
  .ant-form-item {
    margin: 0;
  }
}

.notification-buttons {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  .ant-btn {
    margin-bottom: 17px;
  }
  span {
    font-size: 12px;
  }
}